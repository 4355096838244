import { Link, useLoaderData } from "react-router-dom";
import CustomServices from "./../services/services";
import { GeventFetchUrl, GeventModel, WinnerModel } from "./../model/Gevent.model";
import React, { useContext, useState } from "react";
import { LoginContext, PopUpContext } from "./../store/context";
import CodeGenerator from "./../component/staff/CodeGenerator";
import Form from "./../component/form/Form";
import Input from "./../component/form/Input";
import style from "./stylepage/Gevent.module.css"

function Gevent() {
  let [user , setUser] = useContext(LoginContext);  
  let gevent = useLoaderData() as GeventModel;
  const [popUp, setPopUp] = useContext(PopUpContext);

  const data  = useState<{[name:string]: string}>({email:"" , code: ""});
  const error = useState<{[name:string]: string}>({});

  const winner = useState<WinnerModel | null>(null);

  const isStaff = user?.id &&  gevent.staff.includes(user.id);
  const isMaster = user?.id &&  gevent.master.includes(user.id);
  
 async function searchWinner(e: React.FormEvent<HTMLFormElement>){
  e.preventDefault();
  data[0].email = data[0].email.toString().toLocaleLowerCase();
  let response = await CustomServices.sendFetch<WinnerModel>(GeventFetchUrl.getFindWinner(gevent._id.toString(), data[0].code, data[0].email), {
    method:"GET"
  })

  if(response.error){
    setPopUp({message:<p>{response.error.general}</p>})
  }else{
    data[1](prev => {return {email:"", code:""}})
    winner[1](prev => {return {...response.data}});
  }
 }

 async function toggleWinnerWithdrawn(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
  e.preventDefault();
  let toggle = !winner[0].withdrawn;

  let response = await CustomServices.sendFetch<WinnerModel>(GeventFetchUrl.setWinner(gevent._id.toString()), {
    method:"POST",
    body: JSON.stringify({
      code: winner[0].code,
      email:winner[0].email,
      withdrawn: toggle
    })
  });

  if(response.error){
    console.log("qui")
    setPopUp({message:<p>{response.error.general}</p>})
  }else{
    winner[1](prev => {return {...prev, withdrawn: toggle }});
  }
 }

  return <div className="max900 m_center">
    <h2 className="t_center">{gevent.name}</h2>
    <p className="t_center">{gevent.description}</p>
    {/*TODO: inserire html custom per evento 24/06/2024 */}
    {/*<div dangerouslySetInnerHTML={{__html:event.desHtml}} /> */}
    {((isStaff || isMaster)) && <>
        <Form onSubmit={searchWinner}>
          <h3 className="t_center">Verifica il vincitore</h3>
          <Input type="code" name="code" value={data} error={error}/>
          <Input type="email" name="email" value={data} error={error}/>
          <button className="btn m_center" style={{display:"block"}} type="submit">invia</button>
        </Form>
        {winner[0] && <>
          <table className={style.table}>
            <thead>
              <th>codice</th>
              <th>email</th>
              <th>premio</th>
            </thead>
            <tbody>
              <td>{winner[0].code}</td>
              <td>{winner[0].email}</td>
              <td>{winner[0].withdrawn ? "Ritirato" : "Non ritirato"} 
                <button type="button" onClick={toggleWinnerWithdrawn}>cambia stato</button>
              </td>
              <td></td>
            </tbody>
          </table>
            
        </>}
      </>
    }
    {((isStaff || isMaster) && !gevent.FA) && <>
        <CodeGenerator idGevent={gevent._id} maxTry={5}/>
      </>
    }
    <div className="btn-box btn-box_center">
      <Link className="btn" to="play" title="gioca">Gioca</Link>

    </div>
  </div>
  }

export async function loaderGeventPage(name: string){

  let geventResp = await CustomServices.sendFetch<GeventModel>(GeventFetchUrl.getGevent(name.replaceAll("-"," ")), {
      method: "GET",
  })

  if(geventResp.error){
    alert(geventResp.error.general)
    window.location.href = "../"
  }

  localStorage.setItem(`${name}_src_play`, geventResp.data.src)
  return geventResp.data;
}

export default Gevent;