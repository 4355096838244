import { Schema } from "mongoose";

export type GeventModel ={
    _id: Schema.Types.ObjectId;
    name: string;
    description: string;
    desHtml: string;
    src: string;
    start: Date;
    end:Date;
    runGame: number;
    FA: boolean;
    staff: string[];
    master: string[];
}

export type WinnerModel = {
    code: string, 
    email:string, 
    withdrawn: boolean
}

export class GeventFetchUrl{

    public static getGeventSrc(name:string){
        return `gevent/${name}/src`;
    }


    public static getGevent(name:string){
        return `gevent/${name}`;
    }

    public static getAllGevents(){
        return `gevent`;
    }

    public static getFindWinner(gevent:string, code: string, email:string){
        return `gevent/${gevent}/findwinner/${code}/${email}`;
    }

    public static setWinner(gevent:string){
        return `gevent/${gevent}/setWinner`;
    }
} 